import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import './assets/scss/element-variables.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import router from './router';
import store from './store';
import Paginate from 'vuejs-paginate';

Vue.component('paginate', Paginate);
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
