<template>
  <main class="main">
    <div
      class="login-container container shadow"
      :class="{ 'login-container-twaa': isTwaa }"
    >
      <section class="content">
        <h1 class="heading">
          歡迎使用
          <span>{{ `${title}課程管理系統` }}</span>
        </h1>
        <el-form
          :model="loginForm"
          status-icon
          :rules="rules"
          ref="loginForm"
          class="form-container"
          @submit.native.prevent="login"
        >
          <el-form-item prop="email" :error="error">
            <el-input
              v-model.trim="loginForm.email"
              placeholder="電子郵件地址"
              autofocus="true"
              autocomplete="on"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" :error="error">
            <el-input
              v-model.trim="loginForm.password"
              type="password"
              placeholder="密碼"
              autocomplete="on"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="isKeep" @change="rememberMe">
              記住我
            </el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button
              class="btn"
              type="primary"
              native-type="submit"
              round
              :loading="loading"
            >
              登入
            </el-button>
          </el-form-item>
          <p class="hint">
            <a @click="openDialog">忘記密碼</a>
          </p>
        </el-form>
        <div class="divider"><span>或</span></div>
        <p class="hint">
          還沒有帳號？
          <router-link to="/register">立即註冊！</router-link>
        </p>
      </section>
      <div class="logo" v-if="!isTwaa">
        <img src="@/assets/images/logo.svg" alt="" />
      </div>
    </div>
    <el-dialog
      title="忘記密碼"
      :visible.sync="centerDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :width="width"
      center
    >
      <el-form :rules="rules" :model="passwordForm" ref="passwordForm">
        <el-form-item prop="email" :error="error">
          <el-input
            v-model.trim="passwordForm.email"
            placeholder="電子郵件地址"
            autofocus="true"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click="forgetPassword"
          :loading="passwordLoading"
          >送出</el-button
        >
      </span>
    </el-dialog>
  </main>
</template>

<script>
import { UserLogin, ForgetPassword } from '@/api/api.js';
export default {
  name: 'Login',
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      isTwaa: process.env.VUE_APP_TWAA,
      width: '30%',
      centerDialogVisible: false,
      passwordForm: {
        email: null,
      },
      loginForm: {
        email: '',
        password: '',
      },
      isKeep: false,
      rules: {
        email: [
          { required: true, message: '請輸入電子郵件地址', trigger: 'blur' },
          {
            type: 'email',
            message: '請輸入正確的電子郵件地址',
            trigger: 'blur',
          },
        ],
        password: [{ required: true, message: '請輸入密碼', trigger: 'blur' }],
      },
      error: null,
      loading: false,
      passwordLoading: false,
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    const username = localStorage.getItem('username');
    if (username) {
      this.loginForm.email = username;
      this.isKeep = true;
    } else {
      localStorage.removeItem('username');
      this.isKeep = false;
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 750) {
        this.width = '80%';
      } else if (window.innerWidth < 1000) {
        this.width = '50%';
      } else {
        this.width = '30%';
      }
    },
    login() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          this.error = null;
          UserLogin(this.loginForm)
            .then(() => {
              document.location = process.env.VUE_APP_HOMEPAGE_URL;
            })
            .catch(() => {
              this.loading = false;
              this.error = '帳號或密碼錯誤';
            });
        }
      });
    },
    rememberMe() {
      if (this.isKeep) {
        localStorage.setItem('username', this.loginForm.email);
      } else {
        localStorage.removeItem('username');
      }
    },
    openDialog() {
      this.centerDialogVisible = true;
    },
    async forgetPassword() {
      this.$refs.passwordForm.validate(async validate => {
        try {
          if (validate) {
            this.passwordLoading = true;
            await ForgetPassword(this.passwordForm);
            this.$message({
              message: '已將重設密碼寄至您的電子信箱',
              type: 'success',
            });
            this.centerDialogVisible = false;
            this.passwordForm.email = null;
          }
        } catch (error) {
          if (error.response.status == 422) {
            this.$message({
              message: '查無此電子信箱',
              type: 'error',
            });
          } else {
            this.$message({
              message: '系統錯誤',
              type: 'error',
            });
          }
        } finally {
          this.passwordLoading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.hint {
  cursor: pointer;
}
</style>
