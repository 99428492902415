<template>
  <main class="main edit-container">
    <h1 class="heading bold center">
      課程列表區
      <span>{{ `${title}課程列表區` }}</span>
    </h1>
    <div class="container tab-container">
      <div class="tab-buttons">
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          @click="activeTab = tab.id"
          :class="{ active: activeTab === tab.id }"
        >
          <span class="tab-label">{{ tab.label }}</span>
          <i class="fas" :class="tab.icon"></i>
        </button>
      </div>
      <div class="tab-content">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          v-show="activeTab === tab.id"
          class="tab-panel"
        >
          <div v-if="index === 0">
            <!-- Render content for the first tab -->
            <div
              v-for="(item, index) in tab.items"
              :key="index"
              class="tab-item"
              :id="item.id"
            >
              <span>{{ item.label }}</span>
              <span>{{ item.start_date }} 至 {{ item.end_date }} </span>
              <i
                class="fas"
                :class="item.active ? 'fa-caret-up' : 'fa-caret-down'"
                @click="toggleSubItems(item)"
              ></i>
              <!-- Render sub-items (videos) if they exist -->
              <div v-if="item.active" style="padding: 15px">
                <div
                  v-for="(video, index) in item.videos"
                  :key="index"
                  class="sub-item"
                  :id="video.id"
                >
                  <div style="width: 100%">
                    <div style="">
                      <span>{{ video.label }}</span>
                    </div>
                    <div style="">
                      <a :href="courseURL(video.id)" target="_blank"
                        >進入教室</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              v-for="(item, index) in paginatedItems"
              :key="index"
              class="tab-item"
              :id="item.id"
            >
              <a :href="courseURL(item.id)" target="_blank"
                ><span>{{ item.label }}</span></a
              >
            </div>
            <div>
              <div class="pagination">
                <button @click="prevPage" :disabled="currentPage === 1">
                  Prev
                </button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <button
                  @click="nextPage"
                  :disabled="currentPage === totalPages"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { CourseInfo, VideoInfo, CourseSignUp } from '@/api/api.js';
import 'vue-advanced-cropper/dist/style.css';
export default {
  name: 'Course',
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      courseUrl: process.env.VUE_APP_COURSE_URL,
      perPage: 10,
      currentPage: 1,
      width: '30%',
      courseInfo: {},
      videoInfo: {},
      courseSignUp: {},
      centerDialogVisible: false,
      fileList: [],
      loading: false,
      url: '',
      activeTab: 'by_category',
      tabs: [
        {
          id: 'by_category',
          label: '已購買系列',
          icon: 'fa-caret-down',
          items: [],
          videos: [],
        },
        {
          id: 'by_course',
          label: '已購買課程',
          icon: 'fa-caret-down',
          items: [],
        },
      ],
    };
  },
  created() {
    this.getCourse();
    this.getVideo();
    this.CourseSignUp();
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.tabs[1].items.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.tabs[1].items.length / this.perPage);
    },
  },
  methods: {
    async getCourse() {
      try {
        const courseInfo = await CourseInfo();
        this.courseInfo = courseInfo.data;
        // console.log(this.courseInfo);
        const courses = this.courseInfo.map(item => {
          const startDateTime = new Date(item.start_date);
          const formattedStartDateTime = startDateTime.toLocaleDateString(
            'en-US',
            {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            }
          );
          const course = {
            id: item.id,
            label: item.name,
            start_date: formattedStartDateTime,
            end_date: item.end_date,
            active: false,
          };
          console.log(course);
          return course;
        });
        // push the names and ids to the first tab's items array
        this.tabs[0].items.push(...courses);

        // force a re-render of the tabs components
        this.$forceUpdate();
      } catch {
        alert('Error');
      }
    },
    async getVideo() {
      try {
        const videoInfo = await VideoInfo();
        this.videoInfo = videoInfo.data;
        // console.log(this.videoInfo);
        const video = this.videoInfo.map(item => ({
          id: item.id,
          label: item.name,
          url: item.url,
          active: false,
        }));
        this.tabs[1].items.push(...video);

        this.courseInfo.forEach(course => {
          const videos = this.videoInfo.filter(
            video => video.course_id === course.id
          );

          if (videos.length) {
            const videoItems = videos.map(video => ({
              id: video.id,
              label: video.name,
              url: video.url,
              active: false,
            }));
            course.videos = videoItems;
          }
        });
        this.tabs[0].items = this.courseInfo.map(item => {
          const startDate = new Date(item.start_date);
          const endDate = new Date(item.end_date);
          const formattedStartDate = startDate.toLocaleDateString();
          const formattedEndDate = endDate.toLocaleDateString();

          const course = {
            id: item.id,
            label: item.name,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            active: false,
            videos: item.videos || [],
          };
          //   console.log(course);
          return course;
        });
        this.$forceUpdate();
      } catch {
        alert('Error');
      }
    },
    async CourseSignUp() {
      try {
        const courseSignUp = await CourseSignUp();
        this.courseSignUp = courseSignUp.data;
        // console.log(this.courseSignUp);
        // const namesAndIds = this.courseSignUp.map(item => ({
        //   id: item.id,
        //   label: item.name,
        //   active: false,
        // }));

        // push the names and ids to the first tab's items array
        // this.tabs[0].items.push(...namesAndIds);

        // force a re-render of the tabs components
        // this.$forceUpdate();
      } catch {
        alert('Error');
      }
    },
    prevPage() {
      this.currentPage--;
    },
    nextPage() {
      this.currentPage++;
    },
    toggleSubItems(item) {
      item.active = !item.active;
    },
    courseURL(videoId) {
      return `${process.env.VUE_APP_COURSE_URL}${videoId}`;
    },
  },
};
</script>

<style>
.tab-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tab-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  border-radius: 0.25rem 0 0 0.25rem;
}

.tab-buttons button {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.tab-buttons button.active,
.tab-buttons button:hover {
  background-color: #e6e6e6;
}

.tab-buttons .fa-folder {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.tab-label {
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
}
.tab-content {
  background: #fff;
}

.tab-panel {
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0 0 20px rgba(118, 118, 145, 0.2);
  padding: 1rem;
}

.tab-item {
  flex: 0 0 calc(33.33% - 1rem);
  margin: 15px 0;
  padding: 1rem;
  background-color: #eee;
  border-radius: 0.25rem;
  position: relative;
}

.tab-item span {
  margin-right: 10px;
}

.sub-items {
  display: none;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.sub-items.show {
  display: block;
}

.sub-item {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.sub-item span {
  margin-right: 10px;
}

.sub-item:hover {
  background-color: #f5f5f5;
}

.pagination {
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
}

.pagination button {
  border: none;
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #e0e0e0;
}

.pagination button:disabled {
  cursor: default;
  opacity: 0.5;
}

.pagination span {
  font-weight: bold;
}
</style>
