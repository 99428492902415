import axios from 'axios';
import Qs from 'qs';
import { serialize } from 'object-to-formdata';

const baseURL = `${window.location.origin}/api`;
// const baseURL = 'http://localhost:8000/api';
// const baseURL = process.env.VUE_API_URL;
// const baseURL = process.env.VUE_NODE_ENV == 'production' ? `${window.location.origin}/api` : 'http://localhost:8000/api';

const User = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  timeout: 15 * 1000,
});

export const UserLogin = data => User.post('/auth/login', serialize(data));
export const UserRegister = data =>
  User.post('/auth/register', serialize(data));
export const UserInfo = () => User.get('/user');
export const UserEdit = data => User.post('/user?_method=PUT', serialize(data));
export const Check = data => User.get(`/check?${Qs.stringify(data)}`);
export const AuthCheck = data => User.get(`/auth/check?${Qs.stringify(data)}`);
export const ForgetPassword = data =>
  User.post('/auth/forget-password', serialize(data));
export const CourseInfo = () => User.get('/course');
export const VideoInfo = () => User.get('/video');
export const CourseSignUp = () => User.get('/courseSignUp');
