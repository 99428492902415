<template>
  <main class="main register-container">
    <div class="container shadow">
      <transition name="slide-fade">
        <el-form
          :model="registerForm"
          status-icon
          :rules="rules"
          ref="registerForm1"
          label-position="top"
          label-width="100px"
          class="form-container"
        >
          <section v-show="step === 1">
            <h2 class="heading">
              註冊
              <span>{{ `${title}課程管理系統` }}</span>
            </h2>
            <el-form-item prop="email">
              <el-input
                v-model.trim="registerForm.email"
                placeholder="電子郵件地址"
              ></el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <el-input
                v-model.trim="registerForm.phone"
                placeholder="行動電話號碼"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                v-model.trim="registerForm.password"
                placeholder="設定密碼"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <p class="tip mb-6 md-mb-3">
              請使用 8 個字元以上的英文大小寫字母和數字
            </p>
            <a :href="homepageUrl" class="link">回首頁</a>
            <div class="d-flex md-direction-col md-gap-2">
              <router-link to="/login" class="link">切換至登入</router-link>
              <el-button
                class="btn fix-width"
                type="primary"
                round
                @click="goTo(2)"
              >
                繼續
              </el-button>
            </div>
          </section>
        </el-form>
      </transition>
      <transition name="slide-fade">
        <el-form
          :model="registerForm"
          status-icon
          :rules="rules"
          ref="registerForm2"
          label-position="top"
          label-width="100px"
          class="form-container"
        >
          <section v-show="step === 2">
            <h2 class="heading">基本資料</h2>
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model.trim="registerForm.name"
                placeholder="中文姓名"
                style="margin-bottom: 20px"
              ></el-input>
              <el-input
                v-model.trim="registerForm.englishName"
                placeholder="英文姓名"
              ></el-input>
              <p class="tip">
                提供製作證書之用，請輸入護照或信用卡上之英文姓名
              </p>
            </el-form-item>
            <el-form-item label="性別" prop="gender">
              <el-radio-group v-model="registerForm.gender">
                <el-radio label="m">男性</el-radio>
                <el-radio label="f">女性</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="生日" prop="birthday">
              <el-date-picker
                v-model="registerForm.birthday"
                type="date"
                style="width: 100%"
                placeholder="請選擇日期"
                format="yyyy/MM/dd"
                value-format="yyyy/MM/dd"
                default-value="1985-01-01"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="通訊地址" prop="address">
              <div class="d-flex gap-2 md-direction-col">
                <!-- <el-select
                  placeholder="請選擇"
                  v-model="registerForm.address.city"
                  @change="registerForm.address.district = ''"
                >
                  <el-option value="">-- 縣市 --</el-option>
                  <el-option
                    v-for="city in CityName"
                    :key="city.CityName"
                    :value="city.CityName"
                  >
                    {{ city.CityName }}
                  </el-option>
                </el-select>
                <el-select
                  v-model="registerForm.address.district"
                  placeholder="請選擇"
                >
                  <el-option value="">-- 區域 --</el-option>
                  <el-option
                    v-show="registerForm.address.city"
                    v-for="area in district"
                    :key="area.AreaName"
                    :value="area.AreaName"
                  >
                    {{ area.AreaName }}
                  </el-option>
                </el-select> -->
                <el-input
                  v-model.trim="registerForm.address.road"
                  placeholder="地址"
                ></el-input>
              </div>
              <p class="tip">請詳細填寫以方便寄送教材及證書</p>
            </el-form-item>
            <el-form-item label="通訊軟體" prop="socialMedia">
              <div class="d-flex gap-2 md-direction-col">
                <el-input
                  v-model.trim="registerForm.line"
                  placeholder="LINE ID"
                ></el-input>
                <el-input
                  v-model.trim="registerForm.weChat"
                  placeholder="We Chat"
                ></el-input>
                <el-input
                  v-model.trim="registerForm.whatsApp"
                  placeholder="What's spp"
                ></el-input>
              </div>
              <p class="tip">請至少留一個方式以方便導師聯繫</p>
            </el-form-item>
            <div class="d-flex justify-end">
              <el-button type="primary" round plain @click="goTo(1)"
                >上一步</el-button
              >
              <el-button
                class="btn fix-width"
                type="primary"
                round
                @click="goTo(3)"
                >繼續</el-button
              >
            </div>
          </section>
        </el-form>
      </transition>
      <transition name="slide-fade">
        <el-form
          :model="registerForm"
          status-icon
          :rules="rules"
          ref="registerForm3"
          label-position="top"
          label-width="100px"
          class="form-container"
        >
          <section v-show="step == 3">
            <h2 class="heading">上傳大頭照</h2>
            <el-form-item>
              <el-upload
                action=""
                drag
                class="image-uploader"
                accept="image/jpeg,image/png,image/ico"
                list-type="picture"
                :on-change="handleChange"
                :file-list="fileList"
                :auto-upload="false"
              >
                <i class="far fa-image"></i>
                <div class="el-upload__text">請選擇一張圖片</div>
                <el-button class="btn-secondary" round>選擇檔案</el-button>
              </el-upload>
            </el-form-item>
            <div class="d-flex justify-end">
              <el-button type="primary" round plain @click="goTo(2)"
                >上一步</el-button
              >
              <el-button
                class="btn fix-width"
                type="primary"
                round
                :loading="loading"
                @click.prevent="register(registerForm)"
              >
                完成註冊
              </el-button>
            </div>
          </section>
        </el-form>
      </transition>
    </div>
    <el-dialog
      title="裁切大頭照"
      :visible.sync="centerDialogVisible"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :show-close="true"
      width="30%"
      center
    >
      <cropper
        class="cropper"
        :src="url"
        ref="cropper"
        :stencil-props="{
          aspectRatio: 1 / 1,
        }"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="crop">確定</el-button>
      </span>
    </el-dialog>
  </main>
</template>

<script>
import CityName from '@/config/TaiwanArea.json';
import { UserRegister, Check } from '@/api/api.js';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'Register',
  components: {
    Cropper,
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      homepageUrl: process.env.VUE_APP_HOMEPAGE_URL,
      centerDialogVisible: false,
      step: 1,
      url: null,
      image: null,
      registerForm: {
        name: '',
        englishName: '',
        avatar: null,
        email: '',
        password: '',
        birthday: '',
        gender: '',
        phone: '',
        address: {
          city: '',
          district: '',
          road: '',
        },
        line: '',
        weChat: '',
        whatsApp: '',
      },
      CityName,
      fileList: [],
      rules: {
        email: [
          { required: true, message: '請輸入電子郵件地址', trigger: 'blur' },
          {
            type: 'email',
            message: '請輸入正確的電子郵件地址',
            trigger: 'blur',
          },
          { validator: this.checkEmail, trigger: 'blur' },
        ],
        password: [
          { required: true, message: '請輸入密碼', trigger: 'blur' },
          {
            required: true,
            min: 8,
            message: '請使用 8 個字元以上',
            trigger: 'blur',
          },
          {
            required: true,
            pattern:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])[A-Za-z\d$@$!%*?&]{8,}$/,
            message: '請包含大小寫字母和數字',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            pattern: '^\\+?\\d{8,20}$',
            message: '請輸入正確手機號碼',
            trigger: 'blur',
          },
          {
            validator: this.checkPhone,
            trigger: 'blur',
          },
        ],
        name: [
          { required: true, trigger: 'blur', message: '請填寫中英文姓名' },
        ],
        gender: [{ required: true, trigger: 'change', message: '請選擇性別' }],
        birthday: [{ required: true, trigger: 'blur', message: '請填寫生日' }],
        // socialMedia: [{ validator: this.checkSocialMedia, trigger: 'blur' }],
        address: [{ validator: this.checkAddress, trigger: 'blur' }],
      },
      loading: false,
      hasSocialMedia: false,
      error: null,
    };
  },
  computed: {
    district: function () {
      const city = this.CityName.find(
        city => city.CityName === this.registerForm.address.city
      );
      return city ? city.AreaList : [];
    },
  },
  methods: {
    register(form) {
      const tempForm = { ...form };
      tempForm.address =
        tempForm.address.city +
        tempForm.address.district +
        tempForm.address.road;

      this.$refs.registerForm3.validate(valid => {
        if (valid) {
          this.loading = true;
          this.error = null;
          UserRegister(tempForm)
            .then(() => {
              this.loading = false;
              this.$message({
                message: '註冊成功',
                type: 'success',
              });
              this.$router.push('/login');
            })
            .catch(error => {
              this.loading = false;
              this.error = error.message;

              if (
                error.response.status == 422 &&
                'email' in error.response.data.errors
              ) {
                this.$message({
                  message: '此信箱已註冊過',
                  type: 'error',
                });
              } else {
                this.$message({
                  message: '註冊失敗',
                  type: 'error',
                });
              }
            });
        }
      });
    },
    checkAddress(rule, value, callback) {
      if (value.city === '' && value.district === '' && value.road === '') {
        callback('請填寫通訊地址');
      } else {
        callback();
      }
    },
    // checkSocialMedia(rule, value, callback) {
    //   if (
    //     this.registerForm.line === '' &&
    //     this.registerForm.weChat === '' &&
    //     this.registerForm.whatsApp === ''
    //   ) {
    //     callback('請至少留一個通訊軟體');
    //   } else {
    //     callback();
    //   }
    // },
    checkEmail(rule, value, callback) {
      Check({ email: value })
        .then(() => {
          callback();
        })
        .catch(() => {
          callback('此信箱已註冊過');
        });
    },
    checkPhone(rule, value, callback) {
      Check({ phone: value })
        .then(() => {
          callback();
        })
        .catch(() => {
          callback('此電話號碼已註冊過');
        });
    },
    handleChange(file, fileList) {
      this.url = file.url;
      this.centerDialogVisible = true;
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.fileList = fileList;
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      canvas.toBlob(blob => {
        this.registerForm.avatar = blob;
      });
      this.url = canvas.toDataURL();
      this.fileList[0].url = this.url;
      this.centerDialogVisible = false;
    },
    goTo(step) {
      switch (step) {
        case 1:
          this.step = 1;
          break;
        case 2:
          this.$refs.registerForm1.validate(valid => {
            if (valid) {
              this.step = 2;
            }
          });
          break;
        case 3:
          this.$refs.registerForm2.validate(valid => {
            if (valid) {
              this.step = 3;
            }
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
