<template>
  <main class="main edit-container">
    <h1 class="heading bold center">
      設定個人資訊
      <span>{{ `${title}課程管理系統` }}</span>
    </h1>
    <el-form
      ref="userForm"
      :rules="rules"
      :model="userForm"
      label-position="left"
    >
      <section class="container shadow-sm mb-3">
        <h2 class="heading">基本資訊</h2>
        <el-form-item label="大頭照">
          <div class="d-flex align-start">
            <el-upload
              action=""
              accept="image/jpeg,image/png,image/ico"
              :show-file-list="false"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :file-list="fileList"
              :auto-upload="false"
            >
              <el-button class="edit-upload">點此更換照片</el-button>
            </el-upload>
            <div class="img-preview">
              <img :src="url" alt="Avatar" @error="imageError" />
              <i class="fas fa-camera"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="中文姓名" prop="name">
          <el-input
            v-if="editable.name"
            v-model.trim="userForm.name"
          ></el-input>
          <span v-else>
            {{ userForm.name }}
            <button class="edit" @click.prevent="editable.name = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
        <el-form-item label="英文姓名" prop="english_name">
          <el-input
            v-if="editable.english_name"
            v-model.trim="userForm.english_name"
          ></el-input>
          <span v-else>
            {{ userForm.english_name }}
            <button class="edit" @click.prevent="editable.english_name = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
        <el-form-item label="生日" prop="birthday">
          <el-input
            v-if="editable.birthday"
            v-model.trim="userForm.birthday"
            type="date"
          ></el-input>
          <span v-else>
            {{ userForm.birthday }}
            <button class="edit" @click.prevent="editable.birthday = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
        <el-form-item label="性別" prop="gender">
          <el-radio-group v-if="editable.gender" v-model="userForm.gender">
            <el-radio label="m">男性</el-radio>
            <el-radio label="f">女性</el-radio>
          </el-radio-group>
          <span v-else>
            {{ userForm.gender }}
            <button class="edit" @click.prevent="editable.gender = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
        <el-form-item label="密碼" prop="password">
          <el-input
            v-if="editable.password"
            v-model.trim="userForm.password"
            type="password"
          ></el-input>
          <span v-else>
            {{ userForm.password }}
            <button class="edit" @click.prevent="editable.password = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
      </section>
      <section class="container shadow-sm mb-6 md-mb-3">
        <h2 class="heading">聯絡資訊</h2>
        <el-form-item label="電子郵件" prop="email">
          <el-input
            v-if="editable.email"
            v-model.trim="userForm.email"
            type="email"
          ></el-input>
          <span v-else>
            {{ userForm.email }}
            <button class="edit" @click.prevent="editable.email = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
        <el-form-item label="行動電話" prop="phone">
          <el-input
            v-if="editable.phone"
            v-model.trim="userForm.phone"
          ></el-input>
          <span v-else>
            {{ userForm.phone }}
            <button class="edit" @click.prevent="editable.phone = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
        <el-form-item label="通訊地址">
          <el-input
            v-if="editable.address"
            v-model.trim="userForm.address"
          ></el-input>
          <span v-else>
            {{ userForm.address }}
            <button class="edit" @click.prevent="editable.address = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
        <el-form-item label="LINE">
          <el-input
            v-if="editable.line"
            v-model.trim="userForm.line"
          ></el-input>
          <span v-else>
            {{ userForm.line }}
            <button class="edit" @click.prevent="editable.line = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
        <el-form-item label="We Chat">
          <el-input
            v-if="editable.we_chat"
            v-model.trim="userForm.we_chat"
          ></el-input>
          <span v-else>
            {{ userForm.we_chat }}
            <button class="edit" @click.prevent="editable.we_chat = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
        <el-form-item label="What's app">
          <el-input
            v-if="editable.whats_app"
            v-model.trim="userForm.whats_app"
          ></el-input>
          <span v-else>
            {{ userForm.whats_app }}
            <button class="edit" @click.prevent="editable.whats_app = true">
              <i class="fas fa-pen"></i>
            </button>
          </span>
        </el-form-item>
      </section>
      <div class="d-flex justify-end">
        <el-button type="primary" round plain @click="cancelEdit">
          回首頁
        </el-button>
        <el-button type="primary" round plain @click="course">
          已購買課程
        </el-button>
        <el-button
          class="btn fix-width"
          type="primary"
          round
          :loading="loading"
          @click.prevent="save(userForm)"
        >
          儲存
        </el-button>
      </div>
    </el-form>
    <el-dialog
      title="裁切大頭照"
      :visible.sync="centerDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :width="width"
      center
    >
      <cropper
        class="cropper"
        :src="url"
        ref="cropper"
        :stencil-props="{
          aspectRatio: 1 / 1,
        }"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="crop">確定</el-button>
      </span>
    </el-dialog>
  </main>
</template>

<script>
import { UserInfo, UserEdit, AuthCheck } from '@/api/api.js';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
export default {
  name: 'Edit',
  components: {
    Cropper,
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      width: '30%',
      userInfo: {},
      centerDialogVisible: false,
      rules: {
        email: [
          { required: true, message: '請輸入電子郵件地址', trigger: 'blur' },
          {
            type: 'email',
            message: '請輸入正確的電子郵件地址',
            trigger: 'blur',
          },
          { validator: this.checkEmail, trigger: 'blur' },
        ],
        password: [
          {
            min: 8,
            message: '請使用 8 個字元以上',
            trigger: 'blur',
          },
          {
            pattern:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])[A-Za-z\d$@$!%*?&]{8,}$/,
            message: '請包含大小寫字母和數字',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            pattern: '^\\+?\\d{8,20}$',
            message: '請輸入正確手機號碼',
            trigger: 'blur',
          },
          {
            validator: this.checkPhone,
            trigger: 'blur',
          },
        ],
        name: [{ required: true, trigger: 'blur', message: '請填寫中文姓名' }],
        english_name: [
          { required: true, trigger: 'blur', message: '請填寫英文姓名' },
        ],
        gender: [{ required: true, trigger: 'change', message: '請選擇性別' }],
        birthday: [{ required: true, trigger: 'blur', message: '請填寫生日' }],
        address: [{ validator: this.checkAddress, trigger: 'blur' }],
      },
      userForm: {
        name: null,
        english_name: null,
        avatar: null,
        email: null,
        password: null,
        birthday: null,
        gender: null,
        phone: null,
        address: null,
        line: null,
        we_chat: null,
        whats_app: null,
      },
      editable: {
        name: false,
        english_name: false,
        avatar: false,
        email: false,
        password: false,
        birthday: false,
        gender: false,
        phone: false,
        address: false,
        line: false,
        we_chat: false,
        whats_app: false,
      },
      fileList: [],
      loading: false,
      url: '',
    };
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.getUserInfo();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 750) {
        this.width = '80%';
      } else if (window.innerWidth < 1000) {
        this.width = '50%';
      } else {
        this.width = '30%';
      }
    },
    async getUserInfo() {
      try {
        const userInfo = await UserInfo();
        this.userInfo = userInfo.data;
        for (const key in this.userForm) {
          if (key == 'id') {
            continue;
          }
          this.userForm[key] = userInfo.data[key];
        }
        this.userForm.id = userInfo.data.id;
        this.url = this.userInfo.avatar;
      } catch {
        document.location.href = process.env.VUE_APP_HOMEPAGE_URL;
      }
    },
    save(form) {
      this.$refs.userForm.validate(valid => {
        if (!valid) {
          return;
        }

        let tempForm = {};
        for (const key in form) {
          if (key === 'avatar' && typeof form[key] === 'string') {
            continue;
          }
          tempForm[key] = form[key];
        }
        tempForm.id = form.id;

        this.loading = true;
        this.error = null;
        UserEdit(tempForm)
          .then(() => {
            this.loading = false;
            this.$message({
              message: '修改成功',
              type: 'success',
            });
            setTimeout(() => {
              document.location.href = process.env.VUE_APP_HOMEPAGE_URL;
            }, 3000);
          })
          .catch(error => {
            this.loading = false;
            this.error = error.message;
            this.$message({
              message: '修改失敗，請洽客服人員',
              type: 'error',
            });
          });
      });
    },
    checkEmail(rule, value, callback) {
      AuthCheck({ email: value })
        .then(() => {
          callback();
        })
        .catch(() => {
          callback('此信箱已註冊過');
        });
    },
    checkPhone(rule, value, callback) {
      AuthCheck({ phone: value })
        .then(() => {
          callback();
        })
        .catch(() => {
          callback('此電話號碼已註冊過');
        });
    },
    cancelEdit() {
      document.location.href = process.env.VUE_APP_HOMEPAGE_URL;
    },
    course() {
      document.location.href = '/course';
    },
    handleChange(file) {
      this.userForm.avatar = file.raw;
      this.url = URL.createObjectURL(file.raw);
      this.centerDialogVisible = true;
    },
    handleRemove() {
      this.url = this.userInfo.avatar;
    },
    imageError(e) {
      e.target.src =
        'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      canvas.toBlob(blob => {
        this.userForm.avatar = blob;
      });
      this.url = canvas.toDataURL();
      this.centerDialogVisible = false;
    },
  },
};
</script>
